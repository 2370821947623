import { ActionTypeEnum } from 'types/enums.ts'
import { type VariantProps } from 'class-variance-authority'
import { Form } from '@remix-run/react'
import {
  Button,
  type buttonVariants,
  type ButtonSize,
} from '~/components/ui/inputs/button/Button.tsx'

interface ActionEnableDisableProps extends VariantProps<typeof buttonVariants> {
  status: string
  id: number
  statusCondition: {
    cancel?: string
    enabled: string
    disabled: string
  }
  buttonValue?: {
    enable: string
    disable: string
    size?: ButtonSize
  }
}

export const ActionEnableDisable = ({
  status,
  id,
  statusCondition,
  buttonValue = {
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    size: undefined,
  },
  variant = 'cancel',
}: ActionEnableDisableProps) => {
  if (statusCondition.cancel && status === statusCondition.cancel) {
    return <></>
  }
  const getNewStatus = () => {
    return status === statusCondition.enabled
      ? statusCondition.disabled
      : statusCondition.enabled
  }

  return (
    <Form method="POST" className="flex w-full justify-center">
      <input type="hidden" name="type" value={ActionTypeEnum.enableDisable} />
      <input type="hidden" name="id" value={id} />
      <input type="hidden" name="value" value={getNewStatus()} />
      <Button
        size={buttonValue?.size}
        type="submit"
        variant={variant}
        className="w-full max-w-[250px]"
      >
        {status === statusCondition.disabled
          ? buttonValue.enable
          : buttonValue.disable}
      </Button>
    </Form>
  )
}
